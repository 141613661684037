<template>
  <div
    class="loader"
    :class="{ 'show-loader': loadClass }"
    :style="loaderStyle"
  >
    <template v-if="!showGeneric">
      <div class="logo">
        <img v-if="logoSrc" :src="logoSrc" alt="logo" />
        <div v-if="loader && loader.logoName" class="logo-name">{{ loader.logoName }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loader: Object,
    loadClass: Boolean,
    showGeneric: Boolean,
  },
  computed: {
    logoIsUrl() {
      return this.isValidHttpUrl(this.loader.logoSrc)
    },
    backgroundIsUrl() {
      return this.isValidHttpUrl(this.loader.backgroundSrc)
    },
    loaderStyle() {
      if (this.showGeneric) {
        return {
          "background-color": "black",
        }
      }
      return {
        backgroundColor: "black",
        backgroundImage: this.backgroundIsUrl
            ? `url(${this.loader.backgroundSrc})`
            : `url(${require('@/assets/' + this.loader.backgroundSrc)})`
      }
    },
    logoSrc() {
      if (!this.loader.logoSrc)
      {
        return null;
      }

      return this.logoIsUrl
        ? this.loader.logoSrc
        : require('@/assets/' + this.loader.logoSrc)
    }
  },
  methods: {
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (e) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  }
};
</script>

<style scoped lang="scss">
.loader {
  background-size: cover;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.5s;
  color: white;
  padding: 50px 0 45px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 170px;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &.show-loader {
    opacity: 1;
    transition: 0;
  }

  .header-text {
    font-weight: 500;
    font-size: inherit;
    font-family: "Roboto";
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-height: 175px;
      max-width: 175px;
      margin-bottom: 30px;
    }
  }

  .logo-name {
    font-family: "FreightBig W03 Bold";
    font-size: 80px;
    margin-top: -30px;
    text-shadow: 1px 1px 20px #000;
    max-width: 56vw;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 70px;
      max-width: 80vw;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: inherit;

    .contanct {
      padding-bottom: 10px;
    }

    .title {
      text-transform: uppercase;
    }
  }
}
</style>
