<template>
  <div class="buy-slide" :class="{ 'buy-slide--dark': theme === 'dark' }" :style="{ 'background-image': 'url(' + background + ')' }">
    <a :href="cta_url" target="_blank" v-on:click="click">
      {{ cta_text }}
    </a>
  </div>
</template>

<script>
export default {
  name: "BuySlide",
  props: [
    'slideId',
    'background',
    'cta_text',
    'cta_url',
    'theme',
  ],
  methods: {
    click() {
      this.$gtm.trackEvent({
        event: 'buy_clicked',
        category: 'Buy',
        action: 'click',
        label: 'Buy slide clicked',
        value: this.slideId + '-' + this.cta_text,
        noninteraction: false
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.buy-slide {
  background: #0000000D;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-bottom: calc(35px + 0.5em);

  a {
    background: #ffffff;
    color: #000000;
    padding: 15px 75px;
    border-radius: 10px;
  }

  a:hover {
    background: #d2d2d2;
  }

  &--dark {
    a {
      background: #000000;
      color: #ffffff;
    }

    a:hover {
      background: #5c5c5c;
    }
  }
}
</style>