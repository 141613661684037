<template>
  <div class="section-404">
    <strong class="logo">
      <img :src="require(`@/assets/${page404Settings.logo}`)" alt="logo" />
    </strong>
    <div class="content">
      <img :src="require(`@/assets/${page404Settings.titleImg}`)" alt="title_img" />
      <div class="text">
        <p>{{ page404Settings.text }}</p>
      </div>
      <a :href="page404Settings.btn.link" class="btn">{{ page404Settings.btn.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  data() {
    return {
      page404Settings: {
        logo: "logo_splashscreen_desktop.png",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dignissim ultrices iaculis.",
        titleImg: "img_experience_disabled_desktop.png",
        btn: {
          link: "https://www.google.com/",
          text: "Go back",
        },
      }
    }
  }
};
</script>

<style scoped lang="scss">
.section-404 {
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 120px 0;

  @media (max-width: 1024px) {
    padding: 100px 40px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }

  .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100px;

    @media (max-width: 1024px) {
      max-width: 80px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    .text {
      max-width: 500px;
      font-size: 20px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 15px;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 0 20px 15px 0;
    }
  }

  .btn {
    background: rgb(21, 21, 21);
    border-radius: 50px;
    height: 50px;
    width: 100%;
    max-width: 300px;
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    border: 2px solid transparent;
    transition: 0.3s;

    &:hover {
      background: #242424;
      border: 2px solid rgb(21, 21, 21);
    }

    @media (max-width: 768px) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
