<template>
  <div class="questions__question" :class="{ 'questions--dark': theme === 'dark' }">
    <p class="questions__title">{{ question }}</p>
    <div class="questions__question_wrapper">
      <textarea
        class="questions__question_textarea"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="maxCount"
        :rows="minRows"
        ref="textarea"
        placeholder="Please enter a text ..."
        :disabled="success"
      ></textarea>
      <span class="questions__question_counter">
        {{ remainingCount }}/{{ maxCount }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: ['modelValue', 'question', 'theme', 'check', 'success'],
  emits: ['update:modelValue'],
  watch: {
    modelValue() {
      this.check();
    }
  },
  data() {
    return {
      maxCount: 300,
      remainingCount: 300,
      minRows: 4,
      lineHeight: 20,
      padding: 20
    };
  },
  updated() {
    this.countdown();
    this.resize();
  },
  mounted() {
    this.countdown();
    this.resize();
  },
  methods: {
    countdown: function() {
      if(this.modelValue) {
        this.remainingCount = this.maxCount - this.modelValue.length;
        this.hasError = this.remainingCount < 0;
      }
    },
    resize() {
      const { textarea } = this.$refs;
      const rows = ~~((textarea.scrollHeight - this.padding * 2) / this.lineHeight);
      textarea.style.height = this.lineHeight * this.minRows + 40 + 'px';
      this.minRows = rows;
    }
  }
};
</script>

<style lang="scss" scoped>
$md: 640px;

.questions {
  display: flex;
  flex-direction: column;

  &--dark {
    .questions__title {
      color: #ffffff;
    }

    .questions__question_textarea {
      border: 1px solid rgba(255, 255, 255, 0.25);
      caret-color: #ffffff;
      color: #ffffff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .questions__question_counter {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__title {
    text-align: left;
    font-size: 18px;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;

    @media (max-width: $md) {
      font-size: 15px;
    }
  }

  &__question {
    display: flex;
    flex-direction: column;

    &_wrapper {
      position: relative;
    }

    &_textarea {
      resize: none;
      width: 100%;
      min-height: 121px;
      line-height: 20px;
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 20px;
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 400;
      outline: none;
      overflow: hidden;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_counter {
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
