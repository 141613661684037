<template>
  <div class="rating-slide" :class="{ 'rating-slide--dark': theme === 'dark' }">
    <div class="rating-slide__fragrance">{{ fragrance }}</div>
    <div class="rating-slide__mid">
      <div class="rating-slide__title">{{ title }}</div>
      <div class="rating-slide__subtitle">{{ subtitle }}</div>
      <div class="rating-slide__selector selector" :class="{ 'selector--disabled': selectionDisabled }">
        <div
            :key="index"
            v-for="(index) in [...Array(possibleRatings).keys()]"
            :class="{ 'selector__item': true, 'selector_item--selected': index <= displayedRating }"
            @mouseenter="temporarySelect(index)" @mouseleave="resetDisplay" @click="selectRating(index)"
        >
          <img
              :src="ratingImage(index)"
              alt="selected"
          />
        </div>
      </div>
    </div>
    <div class="rating-slide__submit">
      <button
          :class="{
            'rating-slide__submit-button': true,
            'rating-slide__submit-button--disabled': submitDisabled,
          }"
          @click="submitRating"
      >Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingSlide",
  props: [
    'slideId',
    'fragrance',
    'title',
    'subtitle',
    'theme'
  ],
  data() {
    return {
      possibleRatings: 6,
      displayedRating: -1,
      selectedRating: -1,
      submitDisabled: true,
      selectionDisabled: false,
    }
  },
  methods: {
    temporarySelect(index) {
      this.displayedRating = index;
    },
    resetDisplay() {
      this.displayedRating = this.selectedRating;
    },
    selectRating(index) {
      this.displayedRating = index;
      this.selectedRating = index;
      this.submitDisabled = false;
    },
    async submitRating() {
      this.submitDisabled = true;
      this.selectionDisabled = true;

      try {
        await fetch(
          `${process.env.VUE_APP_ROOT_API}/slide/${this.slideId}/ratings`,
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              // because we count stars from zero throught the component
              rating: this.selectedRating + 1,
            }),
          }
        );
        this.possibleRatings = this.selectedRating + 1


        this.$moshaToast('Rating successfully submitted', {
          'type': 'success',
          'position': 'top-center',
          'showCloseButton': false,
        });
      } catch (e) {
        console.error(e);
        this.$moshaToast({
          title: 'Rating submission failed',
          description: 'Please try again',
        }, {
          'type': 'danger',
          'position': 'top-center',
          'showCloseButton': false,
        });
        this.submitDisabled = false;
      }
    },
    ratingImage(index) {
      if (this.theme === 'dark') {
        return index <= this.displayedRating
            ? require('../assets/icons/Star-Full-Dk.svg')
            : require('../assets/icons/Star-Empty-Dk.svg')
      }
      return index <= this.displayedRating
          ? require('../assets/icons/Star-Full-Lt.svg')
          : require('../assets/icons/Star-Empty-Lt.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
$md: 640px;

.rating-slide {
  background: #0000000D;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  &__fragrance {
    font-family: 'Freight', serif;
    font-size: 30px;
    padding: 10px 50px;
    font-weight: 700;
    background: #ffffff;
    border-radius: 100px;
    margin-top: 80px;
    @media (max-width: $md) {
      font-size: 20px;
    }
  }

  &__mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    font-family: 'Freight', serif;
    font-size: 40px;
    font-weight: 700;
    @media (max-width: $md) {
      font-size: 30px;
    }
  }

  &__subtitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    size: 20px;
    margin-top: 15px;
    @media (max-width: $md) {
      margin-top: 5px;
      font-size: 15px;
    }
  }

  &__selector {
    margin-top: 50px;
    @media (max-width: $md) {
      margin-top: 40px;
    }
  }

  &__submit {
    width: 100%;
    margin-bottom: 45px;
  }

  &__submit-button {
    background: #000000;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    padding: 15px 100px;
    border-radius: 10px;
    font-weight: 700;
    @media (max-width: $md) {
      font-size: 15px;
      margin: 0 25px;
      padding: 15px 0;
      width: calc(100% - 50px);
    }
  }

  &__submit-button--disabled {
    background: #797979 !important;
    color: #f9f9f9;
    pointer-events: none;
    cursor: not-allowed;
  }

  .selector {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 100px;
    padding: 0 60px;
    @media (max-width: $md) {
      padding: 0 13px;
    }

    &__item {
      padding: 30px 10px;
      @media (max-width: $md) {
        padding: 15px 8.5px;
      }

      img {
        height: 40px;
        width: 40px;
        @media (max-width: $md) {
          height: 25px;
          width: 25px;
        }
      }
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &--dark {
    background: #FFFFFF1A;
    color: #ffffff;

    .rating-slide__fragrance {
      background: #000000;
    }

    .rating-slide__fragrance {
      background: #000000;
    }

    .rating-slide__submit-button {
      background: #ffffff;
      color: #000000;
    }

    .selector {
      background: #000000;

      &__item {
        img {
          stroke: white;
        }
      }
    }
  }
}
</style>