<template>
  <div class="h-full w-full flex items-center text-center">
    <splide
      :options="splideOptions"
      @splide:move="activeSlide = -1"
      @splide:active="onSlideChange"
      @splide:inactive="onDeactivation"
    >
      <splide-slide v-for="element in gallery" v-bind:key="element.src">
        <div
          class="slide-container"
          :class="theme == 'light' ? 'bg-light' : 'bg-black'"
        >
          <img
            v-if="element.type === 'image'"
            :src="element.src"
            alt="background"
          />
          <video
            v-else-if="element.type === 'video/mp4'"
            ref="vidRef"
            preload="metadata"
            autoplay
            playsinline
            controls
          >
            <source :src="element.src" :type="element.type" />
          </video>
          <template v-else-if="element.type === 'questionnaire'">
            <questionnaire-slide
              :active-slide="activeSlide"
              :slide-id="element.id"
              :fragrance="title"
              :title="element.questionnaire.title"
              :subtitle="element.questionnaire.subtitle"
              :questions="element.questionnaire.questions"
              :theme="theme"
            />
          </template>
          <template v-else-if="element.type === 'rating'">
            <rating-slide
                :slide-id="element.id"
                :fragrance="title"
                :title="element.rating.title"
                :subtitle="element.rating.subtitle"
                :theme="theme"
            />
          </template>
          <template v-else-if="element.type === 'hologram'">
            <hologram-slide
                :slide-id="element.id"
                :title="element.hologram.hologram.title"
            />
          </template>
          <template v-else-if="element.type === 'buy'">
            <buy-slide
                :slide-id="element.id"
                :background="element.src"
                :cta_text="element.buy.cta_text"
                :cta_url="element.buy.cta_url"
                :theme="theme"
            />
          </template>
        </div>
      </splide-slide>
    </splide>
    <FragranceExperienceOverlay
      :title="title"
      :imageTitle="imageTitle"
      :fragranceStages="fragranceStages"
      :perfumeDescriptions="perfumeDescriptions"
      :overlayConfiguration="overlayConfiguration"
      v-if="activeSlide === evaporationSlide"
    />
  </div>
</template>

<script>
import Video from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import FragranceExperienceOverlay from './FragranceExperienceOverlay.vue';
import QuestionnaireSlide from './QuestionnaireSlide.vue';
import RatingSlide from './RatingSlide.vue';
import HologramSlide from './HologramSlide.vue';
import BuySlide from './BuySlide.vue';

export default {
  name: 'FragranceExperience',
  components: {
    QuestionnaireSlide,
    FragranceExperienceOverlay,
    RatingSlide,
    HologramSlide,
    BuySlide
  },
  data: () => ({
    lastSlideFired: null,
    ready: false,
    activeSlide: -1,
    splideExtensions: {
      Video
    },
    splideOptions: {
      arrows: false,
      pagination: true,
      width: '100%',
      classes: {
        pagination: 'splide__pagination smellviz-pagination bg-black',
        page: 'splide__pagination__page smellviz-whitebg-page'
      }
    }
  }),
  props: {
    gallery: Array,
    title: String,
    imageTitle: String,
    fragranceStages: Array,
    perfumeDescriptions: Object,
    evaporationSlide: Number,
    overlayConfiguration: Object,
    theme: String
  },
  methods: {
    onSlideChange: function(active) {
      this.activeSlide = active._i;
      if (this.ready) {
        this.trackSlideDisplayed();
      }
    },
    onDeactivation: function() {
      if (this.$refs.vidRef) {
        this.$refs.vidRef.pause();
      }
    },
    trackSlideDisplayed: function() {
      if (this.lastSlideFired === this.activeSlide + 1) {
        return;
      }
      this.$gtm.trackEvent({
        event: 'slide_displayed',
        category: 'Slide',
        action: 'scroll',
        label: 'Slide number',
        value: this.activeSlide + 1,
        noninteraction: false
      });
      this.lastSlideFired = this.activeSlide + 1;
    }
  },
  created: function() {
    this.splideOptions.classes.pagination =
      this.theme == 'light'
        ? 'splide__pagination smellviz-pagination bg-white'
        : 'splide__pagination smellviz-pagination bg-black';
    this.splideOptions.classes.page =
      this.theme == 'light'
        ? 'splide__pagination__page smellviz-whitebg-page'
        : 'splide__pagination__page smellviz-blackbg-page';
    if (this.ready) {
      this.trackSlideDisplayed();
    }
  },
  mounted() {
    this.$gtm.trackView('Experience', this.$router.currentRoute.value.path);
    this.ready = true;
  }
};
</script>
