<template>
  <div class="hologram-slide hologram-slide--dark">
    <div class="hologram-slide__mid">
      <button class="open-hologram-button" @click="openHologram(title)">
        start experience
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HologramSlide",
  props: [
    'slideId',
    'title'
  ],
  data() {
    return {}
  },
  methods: {
    openHologram(title) {
      window.location.href = '/holograms/' + title.replaceAll(' ', '-')
    }
  }
}
</script>

<style lang="scss" scoped>
$md: 640px;

.hologram-slide {
  background: #0000000D;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;

  &__mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    & .open-hologram-button {
      background: #8b8b8b;
      padding: 12px 22px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: #6b6b6b;
      }
    }
  }

  &__submit {
    width: 100%;
    margin-bottom: 45px;
  }

  &__submit-button {
    background: #000000;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    padding: 15px 100px;
    border-radius: 10px;
    font-weight: 700;
    @media (max-width: $md) {
      font-size: 15px;
      margin: 0 25px;
      padding: 15px 0;
      width: calc(100% - 50px);
    }
  }

  &--dark {
    background: #000;
    color: #ffffff;

    .hologram-slide__submit-button {
      background: #ffffff;
      color: #000000;
    }
  }
}
</style>