<template>
  <FragrancesModal
    ref="modal"
    :fragranceStages="fragranceStages"
    :imageTitle="imageTitle"
    :title="title"
    :perfumeDescriptions="perfumeDescriptions"
  />
  <div class="absolute h-0 inset-x-0 top-16 select-none z-10">
    <h1
      @click="$refs.modal.open(0)"
      class="pill cursor-pointer pointer-events-auto title-slide"
      :class="`${overlayConfiguration.titleTextColor} ${overlayConfiguration.titleBackgroundColor}`"
    >
      {{ title }}
    </h1>
  </div>

  <div
    :class="`absolute h-4/5 right-3 lg:right-6 pointer-events-none select-none`"
  >
    <div
      :class="`relative h-full w-6 overflow-hidden bg-opacity-60 ${overlayConfiguration.timelineColor} rounded-xl z-10`"
    >
      <div
        v-for="(fragrance, k) in fragranceStages"
        v-bind:key="k"
        class="absolute w-full text-xs text-center font-medium z-30"
        :class="`time-step-${k + 1} ${overlayConfiguration.timelineTextColor}`"
      >
        <div
          v-if="fragrance.isVisible"
        >{{fragrance.time}} <br> {{fragrance.unit}}</div>
      </div>
      <div
        :class="`absolute w-full h-full bottom-0 ${overlayConfiguration.timelineColor} z-20`"
        :style="{
          animation: `timeline-bar-progress ${evaporationDuration}s linear`,
        }"
      ></div>
    </div>
    <div class="absolute h-full w-10 z-10 right-10 top-0">
      <template
        v-for="(fragrance, k) in fragranceStages"
        v-bind:key="`fragrance-label-${k}`"
      >
        <!-- TODO find a way to center vertically -->
        <div
          :class="`absolute label label-step-${k + 1}`"
          :style="{
            top: null,
          }"
        >
          <template
            v-for="(ingredient, kk) in fragrance.ingredients"
            v-bind:key="kk"
          >
            <h2
              @click="linkOnlyIngredientsWithDescription(ingredient)"
              class="absolute pill-sm cursor-pointer pointer-events-auto"
              :class="`${overlayConfiguration.pillTextColor} ${overlayConfiguration.pillBackgroundColor}`"
            >
              {{ ingredient.name }}
            </h2>
            <img
               @click="linkOnlyIngredientsWithDescription(ingredient)"
              class="cursor-pointer pointer-events-auto"
              :src="ingredient.image"
              @error="setAltImg"
              alt=""
            />

          </template>
        </div>
      </template>
    </div>
  </div>
  <div
    id="particle-container"
    class="fixed w-full h-full pointer-events-none select-none"
  />
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import FragrancesModal from "./FragrancesModal.vue";

export default {
  name: "FragranceExperienceOverlay",
  components: {
    FragrancesModal,
  },
  data: () => ({
    evaporationDuration: 30,
    evaporationElapsed: 1,
    evaporationInterval: null,
  }),
  props: {
    title: String,
    imageTitle: String,
    fragranceStages: Array,
    perfumeDescriptions: Object,
    overlayConfiguration: Object,
  },
  mounted() {
    this.evaporationInterval = setInterval(() => {
      this.evaporationElapsed++;
      this.generateParticles();
      if (this.evaporationElapsed > this.evaporationDuration) {
        clearInterval(this.evaporationInterval);
      }
    }, 1000);
  },
  beforeUnmount() {
    if (this.evaporationInterval) {
      clearInterval(this.evaporationInterval);
    }
  },
  methods: {
    generateParticles() {
      for (const stage in this.fragranceStages) {
        for (const ingredient in this.fragranceStages[stage].ingredients) {
          this.spawnParticles(stage, ingredient);
        }
      }
    },
    spawnParticles(stage, ingredient) {
      const intensity = this.getIntensity(stage) / 2;
      if (intensity && Math.random() <= intensity) {
        const container = document.getElementById("particle-container");
        const elem = document.createElement("div");
        const size = anime.random(50, 100);
        const img = new Image();
        const imgSrc = this.fragranceStages[stage].ingredients[ingredient].image;
        const that = this;
        // need to wait for onload to get width/height of the image
        img.onload = function() {
          const left = anime.random(0, window.innerWidth - size);
          elem.className = "absolute";
          // Use scale factor to display properly rectangle images (e.g. 60 x 100 px)
          const scaleFactor = this.width / this.height;
          elem.style = `width: ${size * scaleFactor}px; height: ${size}px; left: ${left}px; bottom: -${size}px; background: url("${imgSrc}") center / contain; background-size:100% 100%;`;
          container.appendChild(elem);
          that.animate(elem, size);
        }
        img.src = this.fragranceStages[stage].ingredients[ingredient].image;
      }
    },
    animate(elem, size) {
      const direction = Math.random() > 0.5 ? -1 : 1;
      anime({
        targets: elem,
        rotate: 360 * anime.random(1.5, 2.5) * direction,
        left: anime.random(0, window.innerWidth - size - 30),
        bottom: window.innerHeight + size,
        easing: "linear",
        duration: anime.random(12000, 15000),
        complete: function () {
          elem.remove();
        },
      });
    },
    getIntensity(stage) {
      const intensityIntervals = this.fragranceStages[stage].intensity;
      const currentTime =
        (this.evaporationElapsed / this.evaporationDuration) * 100;
      if (
        currentTime > intensityIntervals[0] &&
        currentTime <= intensityIntervals[1]
      ) {
        return (
          (currentTime - intensityIntervals[0]) /
          (intensityIntervals[1] - intensityIntervals[0])
        );
      } else if (
        currentTime > intensityIntervals[1] &&
        currentTime < intensityIntervals[2]
      ) {
        return (
          1 -
          (currentTime - intensityIntervals[1]) /
            (intensityIntervals[2] - intensityIntervals[1])
        );
      } else {
        return 0;
      }
    },
    setAltImg(event) {
      event.target.src = require('@/assets/transparent40.png')
    },
    linkOnlyIngredientsWithDescription(ingredient) {
      if (ingredient.description) {
        this.$refs.modal.openOnFragrance(ingredient.name)
      }
    },
  },
};
</script>

<style scoped lang="scss">
$fragrance-label-distance: 10px;
$fragrance-label-image-height: 40px;
$fragrance-label-image-size: 40px;
$fragrance-label-step-y-offset: $fragrance-label-image-size / 2 +
  $fragrance-label-distance / 2;
$line-height: 1.3em;
$peak-points: 20%, 50%, 80%;

@for $i from 1 through length($peak-points) {
  $top: 100% - nth($peak-points, $i);
  .time-step-#{$i} {
    top: calc(#{$top} - #{$line-height} - 1px);
  }
  .label-step-#{$i} {
    top: calc(#{$top} - #{$line-height});
    // top: calc(#{$top} - #{$line-height} - #{$fragrance-label-step-y-offset});
  }
}

.label {
  img:first-of-type {
    margin-bottom: $fragrance-label-distance;
    height: $fragrance-label-image-height;
  }
  h2 {
    right: 50px;
    top: 2px;
  }
  h2:first-of-type {
    top: 100px;
  }
  h2:nth-of-type(2) {
    top: 55px;
  }
  img {
    width: $fragrance-label-image-size;
    height: $fragrance-label-image-height;
    position: relative;
    top: -95px;
  }
  img:first-of-type {
    top: 95px;
  }
  img:nth-of-type(2) {
    top: -5px;
  }
}
.title-slide {
    font-family: FreightBig W03 Bold;
    font-size: 40px;
    letter-spacing: 0.5px;
    text-align: center;
}
</style>
