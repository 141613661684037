<template>
  <div
    class="questionnaire-slide"
    :class="{ 'questionnaire-slide--dark': theme === 'dark' }"
  >
    <div class="questionnaire-slide__fragrance">{{ fragrance }}</div>
    <div class="questionnaire-slide__content">
      <div
        class="questionnaire-slide__questions"
        ref="scrollContainer"
        @scroll.passive="toggleShadow"
      >
        <div class="questionnaire-slide__header">
          <div class="questionnaire-slide__title">{{ title }}</div>
          <div class="questionnaire-slide__subtitle">{{ subtitle }}</div>
        </div>
        <!-- <div class="questionnaire-slide__success" v-if="success">
          Thank you!
          <br />
          Your answers have been
          <br />
          successfully submitted
        </div> -->
        <div :key="item.id" v-for="item in originalQuestions">
          <question
            :success="success"
            :theme="theme"
            :modelValue="item.answer"
            :check="checkSubmit"
            @update:modelValue="
              (newValue) => {
                item.answer = newValue;
              }
            "
            :question="item.text"
          />
        </div>
      </div>
      <div
        class="questionnaire-slide__questions_gradient top"
        :class="{ 'is-active': shadow.top }"
      ></div>
      <div
        class="questionnaire-slide__questions_gradient bottom"
        :class="{ 'is-active': shadow.bottom }"
      ></div>
    </div>
    <div class="questionnaire-slide__submit">
      <!-- <p class="questionnaire-slide__error" v-if="hasError">
        An error has occurred! Please try again
      </p> -->
      <button
        :class="{
          'questionnaire-slide__submit-button': true,
          'questionnaire-slide__submit-button--disabled': submitDisabled || success
        }"
        @click="submitQuestionnaire"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import Question from './Question.vue';

export default {
  name: 'QuestionnaireSlide',
  components: {
    Question
  },
  props: [
    'slideId',
    'fragrance',
    'title',
    'subtitle',
    'theme',
    'questions',
    'activeSlide'
  ],
  data: () => {
    return {
      shadow: {
        top: false,
        bottom: false
      },
      maxCount: 300,
      submitDisabled: true,
      originalQuestions: [],
      hasError: false,
      success: false
    };
  },
  watch: {
    activeSlide() {
      this.makeAnswers();
    }
  },
  methods: {
    async submitQuestionnaire() {
      const answers = this.originalQuestions
        .filter((question) => {
          if (question.answer) {
            return question;
          }
        })
        .map((el) => ({
          slide_questionnaire_question_id: el.id,
          answer: el.answer
        }));

      if (answers.filter((el) => el.answer.length > this.maxCount).length > 0) {
        this.$moshaToast({
          title: 'Questionnaire submission failed',
          description: `Validation failed, at least one question is longer than ${this.maxCount} characters.`,
        }, {
          'type': 'danger',
          'position': 'top-center',
          'showCloseButton': false,
        });
        this.hasError = true;
        this.submitDisabled = false;
        return;
      }

      try {
        await fetch(
          `${process.env.VUE_APP_ROOT_API}/slide/${this.slideId}/questionnaire`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            body: JSON.stringify({ answers })
          }
        );
        this.success = true;
        this.$moshaToast('Questionnaire successfully submitted', {
          'type': 'success',
          'position': 'top-center',
          'showCloseButton': false,
        });
      } catch (e) {
        this.hasError = true;
        this.submitDisabled = false;
        this.$moshaToast({
          title: 'Questionnaire submission failed',
          description: 'Please try again',
        }, {
          'type': 'danger',
          'position': 'top-center',
          'showCloseButton': false,
        });
      }
    },
    toggleShadow() {
      if (this.theme === 'dark') {
        const hasVerticalScrollbar =
          this.$refs.scrollContainer.clientHeight <
          this.$refs.scrollContainer.scrollHeight;
        const scrolledFromTop =
          this.$refs.scrollContainer.offsetHeight + this.$refs.scrollContainer.scrollTop;
        const scrolledToTop = this.$refs.scrollContainer.scrollTop === 0;
        const scrolledToBottom =
          scrolledFromTop >= this.$refs.scrollContainer.scrollHeight;
        this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
        this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
      }
    },
    checkSubmit() {
      const index = this.originalQuestions.findIndex(
          (el) => el.answer.length > 0 && el.answer.length <= this.maxCount
      );
      this.submitDisabled = index >= 0 ? false : true;
    },
    makeAnswers() {
      this.originalQuestions = this.questions.map((question) => ({
        ...question, answer: ''
      }));
    },
  },
  mounted() {
    this.toggleShadow();
    this.makeAnswers();
  }
};
</script>

<style lang="scss" scoped>
$md: 640px;
.questionnaire-slide {
  background: #0000000d;
  height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: fill-available;

  &__fragrance {
    font-family: 'Freight', serif;
    font-size: 30px;
    padding: 10px 50px;
    font-weight: 700;
    background: #ffffff;
    border-radius: 100px;
    margin-top: 80px;
    @media (max-width: $md) {
      font-size: 20px;
    }
  }

  &__title {
    font-family: 'Freight', serif;
    font-size: 40px;
    font-weight: 700;
    @media (max-width: $md) {
      font-size: 30px;
    }
  }

  &__error {
    color: #c30041;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 22px;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-height: 0;
    width: 100%;

    @media (max-width: $md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__success {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: 'Freight', serif;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }

  &__subtitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px;
    @media (max-width: $md) {
      margin-top: 5px;
      font-size: 15px;
    }
  }

  &__questions {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 15px;
    margin-top: 30px;
    scroll-margin-top: 10px;
    gap: 35px;

    @media (max-width: $md) {
      width: 100%;
    }

    &_gradient {
      transform: rotate(-180deg);
      position: absolute;
      margin-left: -15px;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      max-height: 0;
      transition: max-height 1s;

      &.top {
        top: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #191919 100%);
      }

      &.bottom {
        bottom: -2px;
        right: 0;
        background: linear-gradient(180deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
        border-top: 2px solid rgba(0, 0, 0, 0.25);
      }
      &.is-active {
        max-height: 103px;
        height: 103px;
        transition: max-height 1s;
        opacity: 1;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #525252;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      margin-top: 175px;
      margin-bottom: 90px;
    }
  }

  &__submit {
    padding-top: 20px;
    margin-bottom: 45px;
    width: 100%;
    @media (max-width: $md) {
      min-height: 15vh; // hack for iOS
    }
  }

  &__submit-button {
    background: #000000;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    padding: 15px 100px;
    border-radius: 10px;
    font-weight: 700;
    width: 325px;

    @media (max-width: $md) {
      font-size: 15px;
      margin: 0 25px;
      padding: 15px 0;
      width: calc(100% - 50px);
    }
  }

  &__submit-button--disabled {
    background: #797979 !important;
    color: #f9f9f9;
    pointer-events: none;
    cursor: not-allowed;
  }

  &--dark {
    background: #ffffff1a;
    color: #ffffff;

    .questionnaire-slide__fragrance {
      background: #000000;
    }

    .questionnaire-slide__submit {
      background: #191919;
    }

    .questionnaire-slide__submit-button {
      background: #ffffff;
      color: #000000;
    }

    .questionnaire-slide__questions {
      &::-webkit-scrollbar-thumb {
        background-color: #f5f5f5;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #525252;

        border-radius: 2px;
        margin-top: 175px;
        margin-bottom: 90px;
      }
    }
  }
}
</style>
