<template>
  <div class="fixed z-20" :class="{ hidden: hidden }">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-dark"></div>
    </div>

    <div class="fixed inset-0 pb-3 text-white">
      <div class="absolute w-full pt-1">
        <span class="x z-40" @click="close"></span>
      </div>
      <splide ref="slider" :options="splideOptions" @splide:moved="splideMoved" >
        <splide-slide>
          <FragrancesModalSlide :title="title" :image="imageTitle">
            <div
              :class="{'border-b' : index != Object.keys(perfumeDescriptions).length - 1}"
              class="
                border-dark-lighter
                pb-2
                mb-3
                md:text-lg
                lg:text-2xl
              "
              v-for="(value, key, index) in perfumeDescriptions"
              v-bind:key="key"
            >

              <span class="fragrance-modal-slide-title" v-if="value.text">{{ value.text }}: </span>

              <strong class="fragrance-modal-slide-text" v-if="value.text && value.value">{{ value.value }}</strong>
              <span class="fragrance-modal-slide-text storytelling" v-if="!value.text && value.value">{{ value.value }}</span>
            </div>
          </FragrancesModalSlide>
        </splide-slide>
        <splide-slide
          v-for="ingredient in ingredients"
          v-bind:key="ingredient.name"
        >
          <FragrancesModalSlide
            :title="ingredient.name"
            :image="ingredient.image"
          >
            <span
              class="text-sm md:text-lg lg:text-xl"
              v-html="ingredient.description.replaceAll('\n', '<br>')"
            />
          </FragrancesModalSlide>
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
import FragrancesModalSlide from "./FragrancesModalSlide.vue";

export default {
  name: "FragrancesModal",
  components: {
    FragrancesModalSlide,
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);

    this.ingredients = this.fragranceStages.reduce((prev, curr) => {
      return [...prev, ...curr.ingredients];
    }, [])
       .filter(function (ingredient) {
       //  return only ingredients with description
       return ingredient.description
    });

    this.$refs.slider.remount();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  data: () => ({
    hidden: true,
    splideOptions: {
      arrows: false,
      width: "100%",
    },
    ingredients: [],
  }),
  props: {
    fragranceStages: Array,
    title: String,
    imageTitle: String,
    perfumeDescriptions: Object,
  },
  methods: {
    close() {
      this.hidden = true;
    },
    splideMoved(slide, index) {
      let value;
      if (!index) {
        value = this.title;
      } else {
        value = this.ingredients[index - 1].name;
      }
      this.$gtm.trackEvent({
        event: 'ingredient_slide_displayed',
        category: 'Ingredient',
        action: 'slide',
        label: 'Ingredient slide',
        value: value,
        noninteraction: false
      });
    },
    open(slide) {
      let value;
      if (!slide) {
        value = this.title;
      } else {
        value = this.ingredients[slide - 1].name;
      }
      this.$gtm.trackEvent({
        event: 'ingredient_clicked',
        category: 'Ingredient',
        action: 'click',
        label: 'Ingredient clicked',
        value: value,
        noninteraction: false
      });
      this.hidden = false;
      if (slide !== undefined) {
        this.$nextTick(function () {
          this.$refs.slider.go(slide);
        });
      }
    },
    openOnFragrance(name) {
      const idx = this.ingredients.findIndex(ingredient => ingredient.name === name);
      this.open(idx + 1);
    },
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
};
</script>
<style>
.fragrance-modal-slide-title {
  font-family: Georgia;
}
.fragrance-modal-slide-text {
  font-family: Verdana;
}
.storytelling {
  white-space: pre-line;
}
</style>
